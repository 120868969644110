import React from "react"
import { Link, graphql } from "gatsby"
import Bio from "../components/bio"
import Layout from "../components/layout"
import { BlogpostSEO } from "../components/seo"
import TagList from "../components/TagList"
import { rhythm, scale } from "../utils/typography"
import "katex/dist/katex.min.css"
import "../styles/global.css"
import "./blog-post.css"
import format from "date-fns/format"

const BlogPostTemplate = ({ data, pageContext }) => {
  const post = data.markdownRemark
  const siteTitle = "Sagi Kedmi"
  const { previous, next, opengraphImagePath } = pageContext
  const { title, tags, description, draft } = post.frontmatter
  const date = format(new Date(post.frontmatter.date), "MMM d, yy")

  return (
    <Layout title={siteTitle}>
      <BlogpostSEO opengraphImagePath={opengraphImagePath} post={post} />
      <article>
        <header style={{ marginBottom: rhythm(1) }}>
          <p
            style={{
              ...scale(-1 / 5),
              display: `block`,
              margin: 0,
              color: "#585858",
            }}
          >
            {date}
          </p>
          {draft && (
            <h5 style={{ margin: 0, padding: 0, backgroundColor: "yellow" }}>
              DRAFT
            </h5>
          )}

          <h1
            style={{
              marginTop: 0,
              marginBottom: 0,
            }}
          >
            <span
              style={{
                backgroundImage:
                  "linear-gradient(0deg,#d9f5fb 10%,transparent 40%)",
              }}
            >
              {title}
            </span>
          </h1>
          <div style={{ marginBottom: rhythm(0.25) }}>
            <p
              className="header-comment"
              style={{
                color: "#585858",
                display: `block`,
                marginTop: rhythm(0.5),
                marginBottom: rhythm(0.25),
              }}
            >
              {description}
            </p>
            <TagList tags={tags} />
          </div>
        </header>
        <section dangerouslySetInnerHTML={{ __html: post.html }} />
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <footer>
          <Bio />
        </footer>
      </article>

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      id
      excerpt(pruneLength: 160)
      html
      tableOfContents
      frontmatter {
        title
        date
        description
        slugs
        tags
        draft
      }
      timeToRead
    }
  }
`
