/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { rhythm } from "../utils/typography"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/himself_dots.jpg/" }) {
        childImageSharp {
          gatsbyImageData(width: 100, layout: FIXED)
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  const { author, social } = data.site.siteMetadata
  return (
    <div
      style={{
        display: `flex`,
        marginBottom: rhythm(2.5),
      }}
    >
      <a href="/">
        <GatsbyImage
          image={data.avatar.childImageSharp.gatsbyImageData}
          alt={author.name}
          style={{
            clipPath:
              "polygon(45% 0, 73% 9%, 100% 46%, 62% 96%, 9% 100%, 8% 23%)",
            marginRight: 30,
            minWidth: 100,
          }}
        />
      </a>
      <p>
        Feel free to reach out at <a href="mailto:sagi@hey.com">sagi@hey.com</a>
      </p>
    </div>
  )
}

export default Bio
